import { COUNTRY_MAP } from "~/constants/locations";
import { Job } from "~/types/Job";
import { Location } from "~/types/Location";

export const getJobLocationName = (
  location?: Partial<Location>,
  remote?: boolean,
): string | null => {
  const name = getLocationNamePrivate(location);
  if (!name && remote) {
    return "Remote";
  }

  return remote ? name + " (Remote)" : name;
};

export const getLocationNamePrivate = (
  location?: Partial<Location>,
): string | null => {
  const { country, city, name, town, state } = location || {};
  const sanitizedCity = city || town || state;
  if (sanitizedCity && country) {
    return `${sanitizedCity}, ${country}`;
  }

  if (!sanitizedCity && country) {
    return country;
  }

  return name || null;
};

export const getJobLocationFromJob = (job: Job): string | null => {
  if (job.allRemote) {
    return "Remote EMEA";
  }

  return getJobLocationName(
    {
      city: job.city,
      country: COUNTRY_MAP[job.countryCode as keyof typeof COUNTRY_MAP] || "",
    },
    job.remote,
  );
};
