import { companies } from "~/constants/companies";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Job } from "~/types/Job";
import dayjs from "dayjs";

import { Link } from "@remix-run/react";
import { getJobLocationFromJob } from "~/utils/location";

interface JobCardProps {
  job: Job;
}

export function JobCard({ job }: JobCardProps) {
  const onlineSinceCreated = dayjs.duration(
    dayjs().unix() - job.created,
    "seconds",
  );
  const sinceLastSeen = dayjs.duration(
    dayjs().unix() - job.lastSeen,
    "seconds",
  );
  const outdated = sinceLastSeen.asDays() > 3 || !job.lastSeen;
  const isNew = onlineSinceCreated.asDays() < 2 && !outdated;
  const stillLooking =
    onlineSinceCreated.asDays() >= 20 && sinceLastSeen.asDays() < 1;
  const date = job.created;

  const relativeCreatedDate = dayjs.unix(date).from(new Date());
  const createdDate = dayjs.unix(date).format("LL");

  return (
    <li
      key={job.link}
      className={`relative flex flex-col md:flex-row justify-between gap-x-6 px-4 py-5 bg-gray-800 hover:bg-gray-700 sm:px-6 ${
        outdated ? "grayscale" : ""
      } border-b-gray-900 md:border-none border-b`}
    >
      <div className="flex min-w-0 gap-x-4">
        <img
          className="h-12 w-12 flex-none object-contain bg-transparent"
          src={
            companies.find(
              (company) =>
                company.id.toLowerCase() === job.company.toLowerCase(),
            )?.icon
          }
          alt={`${job.company} logo`}
        />
        <div className="min-w-0 flex-auto text-gray-100">
          <p className="text-sm nowrap font-semibold text-left leading-6 text-gray-200">
            <Link to={`/companies/${job.company}/jobs/${job.id}`}>
              <span className="absolute inset-x-0 -top-px bottom-0" />
              {job.title}
            </Link>
          </p>
          <p className="mt-1 flex text-xs leading-5">
            <span>{getJobLocationFromJob(job)}</span>
          </p>
        </div>
      </div>
      <div className="my-auto mr-0 mt-4 md:mt-2 ml-auto flex gap-3 w-full md:w-fit">
        <span className="block md:hidden text-gray-400 text-sm text-left w-full my-auto mr-auto">
          {relativeCreatedDate}
        </span>
        {isNew && (
          <div className="text-lime-400 bg-lime-400/10 ring-lime-400/30 rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset">
            New
          </div>
        )}
        {stillLooking && (
          <div className="text-indigo-200 bg-indigo-400/10 ring-indigo-400/30 rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset">
            Still looking
          </div>
        )}
        {outdated && (
          <div className="text-gray-200 bg-gray-400/10 ring-gray-400/30 rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset">
            Offline
          </div>
        )}
        {job.remote && (
          <div className="text-gray-200 bg-pink-400/30 ring-gray-400/30 rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 ring-inset">
            Remote
          </div>
        )}
      </div>
      <div className="flex shrink-0 items-center gap-x-4">
        <div className="hidden text-right sm:flex sm:flex-col sm:items-end">
          <p className="text-sm leading-6 text-gray-900">{job.remote}</p>
          {job.created ? (
            <p className="mt-1 text-xs leading-5 text-gray-200">
              <span className="text-sm block">
                <time dateTime={""}>{createdDate}</time>
              </span>
              <span className="text-sm block">
                <time dateTime={""}>{relativeCreatedDate}</time>
              </span>
            </p>
          ) : (
            ""
          )}
        </div>
        <ChevronRightIcon
          className="hidden md:visible h-5 w-5 flex-none text-gray-200"
          aria-hidden="true"
        />
      </div>
    </li>
  );
}
